import React, { useState,useEffect } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message, Spin } from "antd";
import { Button } from "antd";

import {
  createSignUpStatus,
  updateSignUpStatus,
} from "../../../../src/utils/helper";
import { CREATE_SHOP_STRIPE_SUBSCRIPTION } from "../../../gqloperations/profile/createShopStripeSubscription.mutation";
import { useMutation } from "@apollo/client";

const CardDetailsForm = (props) => {
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();
  const [createShopStripeSubscription,] = useMutation(CREATE_SHOP_STRIPE_SUBSCRIPTION);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
  const [loading, setLoading] = useState(false)
  const [formFields, setFormFields] = useState({
    cardholderName: "",
    cardNumber: false,
    cvv: false,
    expiry: false,
  });

  useEffect(() => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const cvvElement = elements?.getElement(CardCvcElement);
    const expiryElement = elements?.getElement(CardExpiryElement);
  
    const handleElementChange = (element, field) => {
      if (element?.complete) {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: true,
        }));
      } else {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: false,
        }));
      }
    };
  
    cardNumberElement?.on("change", (event) => {
      handleElementChange(event, "cardNumber");
    });
  
    cvvElement?.on("change", (event) => {
      handleElementChange(event, "cvv");
    });
  
    expiryElement?.on("change", (event) => {
      handleElementChange(event, "expiry");
    });
  }, [elements]);
  

  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isSubmitting || !stripe || !elements) {
      return;
    }
    setLoading(true);
    setIsSubmitting(true);
  
    try {
      // Create payment method with Stripe
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
  
      if (error) {
        message.error("An error occurred while creating payment method.");
        return;
      }
  
      // Prepare input data for subscription
      const input = {
        paymentId: paymentMethod?.id,
        cardHolderName: formFields?.cardholderName,
      };
  
      // Create subscription
      const response = await createShopStripeSubscription(
        { variables: { input }},
        { headers }
      );
      console.log("response", response);
      if (response.data.createShopStripeSubscription) {
        message.success("Subscription created successfully!");
  
        // Retrieve and update state only if subscription succeeds
        const state = JSON.parse(localStorage.getItem("state")) || {};
        const updatedState = {
          state_id: state.id,
          state: state.state + 1,
        };
  
        const updateResponse = await updateSignUpStatus(updatedState);
        if (updateResponse?.data?.message === "State update successfully") {
          state.state += 1;
          localStorage.setItem("state", JSON.stringify(state));
        }
  
        navigate(`/managers/create-profile?state=${updatedState.state}`);
        sessionStorage.removeItem("profile_info");
      } else {
        const errorMsg = response.data.errors?.[0]?.message || "Unknown error occurred";
        message.error(`Failed to create subscription: ${errorMsg}`);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred while processing your request.");
    } finally {
      setIsSubmitting(false);
    }
    setLoading(false);
  };  

  const handleBlur = () => {
    console.log("[blur]");
  };

  const handleFocus = () => {
    console.log("[focus]");
  };
  const handleReady = () => {
    console.log("[ready]");
  };

 const handleFieldChange = (field, value) => {
    if (field === "cardholderName") {
      // Check if the input value contains only letters and spaces
      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: value,
        }));
      } else {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: "",
        }));
      }
    } else if (value.length > 20) {
      // Prevent updating the state if the length exceeds 20 characters
      return;
    } else {
      setFormFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
    }
  };
  
  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize: "20px",
          color: "white",
          letterSpacing: "0.025em",
          border: "2px solid red",
          "::placeholder": {
            color: "#D4D4D4",
            fontSize: "18px",
          },
          padding,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };
  return (
    <>
      <Spin tip="Please wait... dont go back or reload page..." spinning={loading} >
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
        <h4 style={{ fontSize: "40px", marginBottom: "12px", fontWeight: 800 }}>
          Enter Cards Details
        </h4>
        <div className="row">
          <div className="col-12">
            <p
              style={{
                whiteSpace: "normal",
                maxWidth: "531px",
                margin: "auto",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "32px",
                color: "#dadada",
                opacity: 0.7,
              }}
            >
              Enter your Credit Card information - iONclassic uses stripe online
              payment system
            </p>
          </div>
        </div>
      </div>
      <div
        className="common-form"
        style={{ maxWidth: "597px", margin: "0 auto" }}
      >
        <form id="payment-form" onSubmit={handleSubmit}>
          <div style={{ paddingBottom: "32px" }}>
            <label
              style={{
                fontSize: "21px",
                fontWeight: 600,
                lineHeight: "normal",
                paddingBottom: 10,
                color: "white",
                marginTop: "17px",
              }}
            >
              Cardholder's Name
            </label>
            <input
              className="input-box"
              type="text"
              src=""
              alt=""
              value={formFields.cardholderName} 
              placeholder="Cardholder's Name"
              onChange={(event) =>
                handleFieldChange("cardholderName", event.target.value)
              }
            />
          </div>

          <div style={{ paddingBottom: "32px" }}>
            <label
              style={{
                fontSize: "21px",
                fontWeight: 600,
                lineHeight: "normal",
                paddingBottom: 10,
                color: "white",
              }}
            >
              Card Number
            </label>
            <CardNumberElement
              className="input-box"
              onBlur={handleBlur}
              // onChange={handleChange}
              onChange={(event) =>
                handleFieldChange("cardNumber", event.error ? "" : "filled")
              }
              onFocus={handleFocus}
              onReady={handleReady}
              options={{
                ...createOptions(),
                placeholder: "XXXX XXXX XXXX XXXX",
              }}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ paddingBottom: "32px", flex: 1 }}>
              <label
                style={{
                  fontSize: "21px",
                  fontWeight: 600,
                  lineHeight: "normal",
                  paddingBottom: 10,
                  color: "white",
                }}
              >
                CVV
              </label>
              <CardCvcElement
                className="input-box"
                onBlur={handleBlur}
                onChange={(event) =>
                  handleFieldChange("cvv", event.error ? "" : "filled")
                }
                onFocus={handleFocus}
                onReady={handleReady}
                options={{
                  ...createOptions(),
                  placeholder: "XXXX",
                }}
              />
            </div>

            <div style={{ paddingBottom: "32px", flex: 1 }}>
              <label
                style={{
                  fontSize: "21px",
                  fontWeight: 600,
                  lineHeight: "normal",
                  paddingBottom: 10,
                  color: "white",
                }}
              >
                Expiry Date
              </label>
              <CardExpiryElement
                className="input-box"
                onBlur={handleBlur}
                onChange={(event) =>
                  handleFieldChange("expiry", event.error ? "" : "filled")
                }
                onFocus={handleFocus}
                onReady={handleReady}
                options={createOptions()}
              />
            </div>
          </div>

          <div className="col-12">
            <Button
              className="theme-common-btn subscribe-btn w-100"
              type="primary"
              htmlType="submit"
              disabled={
                !formFields.cardholderName ||
                !formFields.cardNumber ||
                !formFields.cvv ||
                !formFields.expiry
              }
            >
              {isSubmitting ? "Subscribe Now" : "Subscribe Now"}
            </Button>
          </div>
        </form>
      </div>
      </Spin>
    </>
  );
};
export default CardDetailsForm;
