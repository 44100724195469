import { gql } from "@apollo/client";
// const { auth_token } = JSON.parse(localStorage.getItem("userDetails"));
export const CREATE_SHOP_STRIPE_SUBSCRIPTION = gql`
      mutation CreateShopStripeSubscription($input: CreateShopStripeSubscriptionInput!) {
        createShopStripeSubscription(input: $input) {
          clientMutationId
        }
      }
    
`;
