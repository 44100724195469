import "./restoration-success.scss";
import { Image as Images } from "../../components/Images";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

export const RestorationSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/managers/home/details/categories");
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="restoration-container">
      <div className="restoration-message">
        <img
          src={Images.restoration_success_icon}
          alt="success"
          style={{ height: "60px" }}
        />
        <h3 className="mb-0 text-white" style={{ fontFamily: "Manrope" }}>
          Your Restoration has been added successfully.
        </h3>
      </div>
    </div>
  );
};
