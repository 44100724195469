import "./car-sub-categories-list.scss";
import { Breadcrumb, Button, Col, Input, Row, Select, Typography } from "antd";
import { Image as Images } from "../../components/Images";
import { useNavigate } from "react-router-dom";
import AfterLoginHeader from "../../components/common/AfterLoginHeader";
import React from "react";
import TextArea from "antd/es/input/TextArea";

export const CarSubCategoriesList = () => {
  const navigate = useNavigate();

  const handleSave = () => {
    navigate("/managers/home/details/restoration-success");
  };

  const subCategoryList = [
    {
      imageSrc: Images.category_img_1,
    },
    {
      imageSrc: Images.category_img_2,
    },
    {
      imageSrc: Images.category_img_3,
    },
    {
      imageSrc: Images.category_img_4,
    },
  ];

  return (
    <div>
      <AfterLoginHeader />
      <div className="container-fluid categories-main-fluid pt-5">
        <div className="container">
          <div className="mb-3">
            <Breadcrumb
              className="iON-breadcrumb"
              separator=">"
              items={[
                {
                  title: "Mercedes-Benz Maybach GLS 600 4MATIC",
                },
                {
                  title: "Category",
                },
              ]}
            />
          </div>
          <div className="row categories-row-heading">
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <h1
                className="mb-0"
                style={{ fontFamily: "Manrope", textTransform: "capitalize" }}
              >
                Sub-Categories
              </h1>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <ul className="list-inline mb-0 text-end">
                <li className="list-inline-item">
                  <Button
                    className="add-restoration-btn categories-save-btn border-0"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="row gap-4">
              <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <h3
                  className="mb-0 text-white"
                  style={{ fontFamily: "Manrope", textTransform: "capitalize" }}
                >
                  Body Work
                </h3>
              </div>
              {subCategoryList.map((data) => (
                <div className="d-flex" style={{ gap: "40px" }}>
                  <div
                    className="col-12 category-img-div position-relative"
                    style={{ width: "321px" }}
                  >
                    <img
                      src={data.imageSrc}
                      alt=""
                      className="img-fluid"
                      style={{
                        height: "321px",
                        width: "321px",
                        objectFit: "cover",
                        borderRadius: "12px",
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-xl-6 col-lg-6">
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                      }}
                    >
                      <Row align="middle" style={{ gap: "12px" }}>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                            fontWeight: "400",
                            fontSize: "17px",
                          }}
                        >
                          Classify
                        </Typography.Title>
                        <Select
                          defaultValue="Before"
                          className="categories-custom-select"
                          popupClassName="categories-custom-select-popup"
                          options={[
                            {
                              value: "After",
                              label: "After",
                            },
                            {
                              value: "Before",
                              label: "Before",
                            },
                            {
                              value: "Showroom",
                              label: "Showroom",
                            },
                            {
                              value: "In Shop",
                              label: "In Shop",
                            },
                            {
                              value: "None",
                              label: "None",
                            },
                          ]}
                        />
                      </Row>
                      <div>
                        <Typography.Title
                          level={5}
                          className="flex-center"
                          style={{
                            fontWeight: "600",
                            fontSize: "21px",
                            marginBottom: "6px",
                          }}
                        >
                          Job Number/ Invoice
                          <div
                            className="car-image-upload-container"
                            style={{ margin: "0" }}
                          >
                            <input
                              type="file"
                              name="myfile"
                              multiple
                              accept=".pdf"
                            />

                            <img
                              src={Images.add_outline_icon}
                              alt="upload"
                              style={{ marginLeft: "6px" }}
                            />
                          </div>
                        </Typography.Title>

                        <div className="categories-input-container">
                          <Input
                            placeholder="Enter Job Number/ Invoice"
                            style={{ lineHeight: 0, paddingRight: "40px" }}
                            className="categories-custom-input"
                            variant="borderless"
                          />

                          <div>
                            <div className="car-image-upload-container upload-image-icon">
                              <input
                                type="file"
                                name="myfile"
                                multiple
                                accept=".pdf"
                              />

                              <img
                                src={Images.attach_image_icon}
                                alt="upload"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Typography.Title
                          level={5}
                          style={{
                            fontWeight: "600",
                            fontSize: "21px",
                            marginBottom: "6px",
                          }}
                        >
                          Add Comment
                        </Typography.Title>
                        <TextArea
                          placeholder="Type Here"
                          className="categories-custom-input categories-custom-text-area"
                          variant="borderless"
                        />
                      </div>
                    </Col>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
